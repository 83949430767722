//存放项目共同的css and js
window.$ = require("jquery");
$(document).on('turbolinks:load',function () {
    $(".tit-two span").click(function () {
        var i = $(this).index();
        $(this).addClass('select').siblings().removeClass('select');
        $(".content li").eq(i).show().siblings().hide();
    });
//    合作伙伴
    var _index5=0;
    $("#four_flash .but_right").click(function(){
        _index5++;
        var len=$(".flashBg ul.mobile li").length;
        if(_index5+5>len){
            $("#four_flash .flashBg ul.mobile").stop().append($("ul.mobile").html());
        }
        $("#four_flash .flashBg ul.mobile").stop().animate({left:-_index5*189},1000);
    });


    $("#four_flash .but_left").click(function(){
        if(_index5==0){
            $("ul.mobile").prepend($("ul.mobile").html());
            $("ul.mobile").css("left","-1000px");
            _index5=6
        }
        _index5--;
        $("#four_flash .flashBg ul.mobile").stop().animate({left:-_index5*189},1000);
    });

//    运价查询
    $(".inquery-tab li.lcl").click(function () {
        var i = $(this).index();
        $(this).addClass('selectone').siblings().removeClass('selecttwo').removeClass('selectthree');

        $(".type-li li.input-search").eq(i).show().siblings().hide();
    });
    $(".inquery-tab li.fcl").click(function () {
        var i = $(this).index();
        $(this).addClass('selecttwo').siblings().removeClass('selectone').removeClass('selectthree');

        $(".type-li li.input-search").eq(i).show().siblings().hide();
    });

    $(".inquery-tab li.trailer").click(function () {
        var i = $(this).index();
        $(this).addClass('selectthree').siblings().removeClass('selecttwo').removeClass('selectone');
        $(".type-li li.input-search").eq(i).show().siblings().hide();
    });



});





